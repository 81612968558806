import Vue from 'vue';
const validateInputImportMixin = {
  data() {
    return {
      validatedInputComponent: null,
      scriptValidatedInputLoaded: false,
    };
  },
  async created() {
    if (process.client) {
      const { ValidatedInput } = await import('@mtntop/utils/src/plugins/mpowerInputsInit');
      Vue.component('validated-input', ValidatedInput);
      this.validatedInputComponent = ValidatedInput;
      setTimeout(() => (this.scriptValidatedInputLoaded = true));
    }
  },
};
const autocompleteImportMixin = {
  data() {
    return {
      scriptAutoCompleteInputLoaded: false,
    };
  },
  async created() {
    if (process.client && !this.scriptAutoCompleteInputLoaded) {
      const { AutoCompleteInput, Multiselect } = await import('@mtntop/utils/src/plugins/mpowerInputsInit');
      Vue.component('multiselect', Multiselect);
      setTimeout(() => {
        Vue.component('autocomplete-input', AutoCompleteInput);
        this.scriptAutoCompleteInputLoaded = true;
      });
    }
  },
};
const multiselectImportMixin = {
  data() {
    return {
      scriptMultiselectLoaded: false,
    };
  },
  async created() {
    if (process.client) {
      const { Multiselect } = await import('@mtntop/utils/src/plugins/mpowerInputsInit');
      Vue.component('multiselect', Multiselect);
      setTimeout(() => (this.scriptMultiselectLoaded = true));
    }
  },
};
export { validateInputImportMixin, autocompleteImportMixin, multiselectImportMixin };
